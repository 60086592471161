<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 14.634L12.5 16.366C12.1906 16.5446 11.8094 16.5446 11.5 16.366L8.5 14.634C8.19061 14.4553 8.00001 14.1252 8 13.768V10.232C8.00001 9.87472 8.19061 9.5446 8.5 9.36597L11.5 7.63397C11.8094 7.45534 12.1906 7.45534 12.5 7.63397L15.5 9.36597C15.8094 9.5446 16 9.87472 16 10.232V13.768C16 14.1252 15.8094 14.4553 15.5 14.634Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.389 17.5606L13.0557 21.2172C12.4025 21.5943 11.5977 21.5943 10.9446 21.2172L4.61122 17.5606C3.95804 17.1835 3.55566 16.4866 3.55566 15.7324V8.26763C3.55566 7.51341 3.95804 6.81647 4.61122 6.43936L10.9446 2.78283C11.5977 2.40572 12.4025 2.40572 13.0557 2.78283L19.389 6.43936C20.0422 6.81647 20.4445 7.51341 20.4445 8.26763V15.7324C20.4445 16.4866 20.0422 17.1835 19.389 17.5606V17.5606Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.1615 16.788L18.0137 15.528" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.83838 7.21198L5.98621 8.47199" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.83838 16.788L5.98621 15.528" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.1615 7.21198L18.0137 8.47199" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 19V21.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 5V2.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.8658 9.73199L12.4998 11.6754C12.1904 11.854 11.8092 11.854 11.4998 11.6754L8.13379 9.73199" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 16.5V11.8085" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>